<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Tags
      </h5>
    </b-card-header>
    <b-card-body>
      <b-badge
        v-for="tag in applicationData.tags"
        :key="tag.name"
        :variant="`light-${tag.color}`"
        class="mr-1 mt-1"
      >
        {{ tag.name }}
      </b-badge>
      <!-- <img v-for="service in ['Okta', 'Gsuite', 'Asana', 'Gitlab', 'Github', 'DropBox', 'Workday']" :key="service"
        width="42"
        class="mr-1 pt-1"
        :src="require(`@/assets/images/logos/${service}.webp`)"
      /> -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
  },
  props: {
    applicationData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
