<template>

  <div>
    <checker />
    <b-card
      no-body
    >
      <b-card-body class="px-0">
        <div class="mx-2 mb-2" v-if="0">
          <b-row>
            <!-- Selection -->
            <b-col cols="12">
              <label>Quick Filters</label>
              <b-row class="ml-1">
                <b-button
                  v-for="type in ['Active Scenarios (58)', 'Disabled Scenarios (2)', 'IAM (30)', 'DLP (25)', 'MALOPS (15)', 'UBA (10)', 'Vulenrability Detected (4)', 'High (20)', 'Medium (30)', 'Low (8)']"
                  :key="type"
                  class="mr-1"
                  size="sm"
                >
                  {{ type }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div class="mx-2 mb-2" v-if="0">
          <b-row v-if="!query">
            <!-- Selection -->
            <b-col cols="12">
              <label>Filter by Application</label>
              <b-row class="ml-1">
                <b-button
                  v-for="service in ['Salesforce', 'Slack', 'Splunk', 'Gitlab', 'Gsuite', 'ServiceNow', 'Workday', 'Monday.com', 'Zoom', 'Webex', 'SAP', 'NetSuite']"
                  :key="service"
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon border-0 mr-1"
                  @click="toggleService(service)"
                >
                  <img
                    width="32"
                    :src="require(`@/assets/images/logos/${service}.webp`)"
                  >
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="6"
              class="mb-1"
            >
              <label>Entries per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector mx-50"
              />
            </b-col>
            <b-col
              cols="6"
            >
              <label>Quick Search</label>
              <b-form-input
                v-model="searchQuery"
                class="mr-1"
                placeholder="Search..."
              />
            </b-col>
            <!-- Selection -->
            <b-col cols="12">
              <label>Show Columns</label>
              <v-select
                v-model="selected"
                multiple
                style="width:100%"
                :options="Object.keys(tableColumns)"
                @input="setSelected"
              />
            </b-col>
            <!-- Search -->
          </b-row>

        </div>

        <b-table
          class="position-relative"
          :items="showScenarios"
          responsive
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          id="scenarios-table"
          :fields="Object.values(tableColumns)"
          selectable
          show-empty
          @row-selected="selectColumn"
          empty-text="No matching records found"
        >
          <template #cell(state)="data">
            <b-badge
              v-if="data.item.is_premium"
              pill
              variant="light-dark"
              class="text-capitalize"
            >
              Upgrade to premium
            </b-badge>
            <b-badge
              v-else-if="data.item.failed"
              pill
              variant="light-danger"
              class="text-capitalize"
            >
              {{ data.item.error }}
            </b-badge>
            <b-badge
              v-else
              pill
              variant="light-success"
              class="text-capitalize"
            >
              {{ data.item.success }}
            </b-badge>
          </template>
          <template #cell(result)="data">
            <span
              v-for="item in data.item.result"
              :key="item.name"
            >
              <feather-icon
                :icon="item.icon"
                size="18"
              /> {{ item.name }}
            </span>
          </template>
          <template #cell(impact)="data">
            {{ data.item.impact.join(", ") }}
          </template>
          <template #cell(risks)="data">
            <div v-if="data.item.failed">
              <div v-if="data.item.resources.length">
                {{ data.item.resources.length }} Resources
              </div>
              <div v-if="data.item.actors.length">
                {{ data.item.actors.length }} Identities
              </div>
              <div v-if="data.item.configurations.length">
                {{ data.item.configurations.length }} Configs
              </div>
            </div>
          </template>
          <template #cell(description)="data">
              {{ data.item.description }}
          </template>
          <template #cell(app)="data">
              {{ data.item.services[data.item.app_name].section }}
          </template>
          <template #cell(title)="data">
              {{ data.item.title }}
          </template>
          <template #cell(root)="data">
            {{ data.item.root.join(", ") }}
          </template>
          <template #cell(standards)="data">
            {{ data.item.standards ? Object.keys(data.item.standards).join(', ') : '' }}
          </template>
          <template #cell(app_name)="data">
              <img
                width="32"
                style="margin-right: 8px"
                :src="require(`@/assets/images/logos/${data.item.app_name}.webp`)"
              >
          </template>
          <template #cell(priority)="data">
            <b-badge
              :variant="`light-${getColor(data.item.priority)}`"
              class="mr-1"
            >
              {{ data.item.priority }}
            </b-badge>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                aria-controls="scenarios-table"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                v-model="currentPage"
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BPagination, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useScenariosList from './api/useScenariosList'
import scenarioStoreModule from './api/scenarioStoreModule'
import checker from './Checker.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCardBody,
    BTable,
    BPagination,
    BBadge,
    vSelect,
    checker,
  },
  data() {
    return {
      option: [],
      selected: [],
      toggledServices: [],
      states: {},
      scenarios: [],
      perPage: 25,
      currentPage: 1,
      tableColumns: {
        app_name: {
          key: 'app_name',
          sortable: true,
          label: 'Service',
          thStyle: {
            width: '15px',
          },
        },
        app: {
          key: 'app',
          sortable: true,
          label: 'App',
          thStyle: {
            width: '15px',
          },
        },
        title: { key: 'title', sortable: true },
        state: { key: 'state', sortable: true },
        risks: {
          key: 'risks',
          sortable: true,
          thStyle: {
            width: '30px',
          },
          tdStyle: {
            width: '30px',
          },
        },
        category: {
          key: 'category',
          label: 'Category',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        // subCategory: { key: 'subCategory', sortable: true },
        standards: {
          key: 'standards',
          label: 'Stadards',
        },
        impact: {
          key: 'impact',
          label: 'Potential Impact',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        root: {
          key: 'root',
          label: 'Root Cause',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        priority: { key: 'priority', sortable: true },
      },
    }
  },
  mounted() {
    this.selected = Object.keys(this.tableColumns)
    const tthis = this
    this.selected = this.selected.filter(col => tthis.tableColumns[col].thClass !== 'd-none')
    if (this.query != null) {
      this.searchQuery = this.query
    }
    const scenarios = JSON.parse(localStorage.getItem('scenarios'))
    if (scenarios) {
      this.scenarios = scenarios
    }
    this.fetchScenarios(null, this.initScenarios)
  },
  methods: {
    async getState(id) {
      await store.dispatch('app-scenario/fetchScenario', { id })
        .then(response => {
          this.states[id] = response
        })
    },
    selectColumn(item) {
      if (!item[0].is_premium) {
        this.$router.push({ path: `/apps/scenarios/view/${item[0].id}` })
      }
    },
    initScenarios(scenarios) {
      this.scenarios = scenarios
      this.scenarios = JSON.parse(localStorage.getItem('scenarios'))
    },
    setSelected(value) {
      const tthis = this
      Object.keys(this.tableColumns).forEach(col => {
        if (value.includes(col)) {
          tthis.tableColumns[col].thClass = ''
          tthis.tableColumns[col].tdClass = ''
        } else {
          tthis.tableColumns[col].thClass = 'd-none'
          tthis.tableColumns[col].tdClass = 'd-none'
        }
      })
    },
    toggleService(service) {
      if (this.toggledServices.includes(service)) {
        this.toggledServices = this.toggledServices.filter(item => ![service].includes(item))
      } else {
        this.toggledServices.push(service)
      }
    },
  },
  computed: {
    showScenarios() {
      if (this.$route.name === 'scenarios-failed') {
        return this.scenarios.filter(element => element && element.failed)
      }
      if (this.$route.name === 'scenarios-inprogress') {
        return this.scenarios.filter(element => !element.state)
      }
      return this.scenarios
    },
    totalRows() {
      return this.scenarios.length
    },
  },
  setup() {
    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(SCENARIO_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchScenarios,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useScenariosList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      fetchScenarios,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  props: {
    query: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
