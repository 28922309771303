<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions <b>of</b> other apps</b-card-title>
      <b-card-sub-title>Who has access to {{applicationData.name}}</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(service)="data">
        <img
          width="32"
          class="mr-1"
          :src="require(`@/assets/images/logos/${data.value}.webp`)"
        >
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  setup() {
    const permissionsData = [
      {
        service: 'Gsuite',
        read: true,
        write: false,
        create: false,
        delete: false,
        share: false,
        manage: false,
      },
      {
        service: 'Asana',
        read: false,
        write: true,
        create: false,
        delete: false,
        share: false,
        manage: false,
      },
      {
        service: 'Zoom',
        read: true,
        write: false,
        create: true,
        delete: false,
        share: false,
        manage: true,
      },
      {
        service: 'Github',
        read: false,
        write: false,
        create: false,
        delete: false,
        share: true,
        manage: true,
      },
      {
        service: 'Splunk',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    return {
      permissionsData,
    }
  },
  props: {
    applicationData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
