<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="applicationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching application data
      </h4>
      <div class="alert-body">
        No application found with this application id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-applications-list'}"
        >
          Application List
        </b-link>
        for other applications.
      </div>
    </b-alert>

    <template v-if="applicationData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <application-view-application-info-card :application-data="applicationData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <application-view-application-plan-card :application-data="applicationData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <application-view-application-permissions-card-external :application-data="applicationData" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <application-view-application-permissions-card :application-data="applicationData" />
        </b-col>
      </b-row>

      <scenarios :query="applicationData.name" />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import Scenarios from '@/views/app/Scenarios.vue'
import applicationStoreModule from '../api/applicationStoreModule'
import ApplicationViewApplicationInfoCard from './ApplicationViewApplicationInfoCard.vue'
import ApplicationViewApplicationPlanCard from './ApplicationViewApplicationPlanCard.vue'
import ApplicationViewApplicationPermissionsCard from './ApplicationViewApplicationPermissionsCard.vue'
import ApplicationViewApplicationPermissionsCardExternal from './ApplicationViewApplicationPermissionsCardExternal.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ApplicationViewApplicationInfoCard,
    ApplicationViewApplicationPlanCard,
    ApplicationViewApplicationPermissionsCardExternal,
    ApplicationViewApplicationPermissionsCard,

    Scenarios,
  },
  setup() {
    const applicationData = ref(null)

    const APPLICATION_APP_STORE_MODULE_NAME = 'app-application'

    // Register module
    if (!store.hasModule(APPLICATION_APP_STORE_MODULE_NAME)) store.registerModule(APPLICATION_APP_STORE_MODULE_NAME, applicationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APPLICATION_APP_STORE_MODULE_NAME)) store.unregisterModule(APPLICATION_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-application/fetchApplication', { id: router.currentRoute.params.id })
      .then(response => { applicationData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          applicationData.value = undefined
        }
      })

    return {
      applicationData,
    }
  },
}
</script>

<style>

</style>
